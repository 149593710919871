<template>
	<div class="grid">
    <div class="col-12">
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button v-if="hide" label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="onNew" />
          </div>
        </template>
      </Toolbar>
    </div>
		<div class="col-12">
			<div class="card">
				<DataTable :value="inspektionen"
                    sortMode="single" sortField="ID" :sortOrder="1" scrollable scrollHeight="400px" selectionMode="single" @rowSelect="onRowSelect" responsiveLayout="stack" breakpoint="460px">
                    <Column field="ID" header="Id"></Column>
                    <Column header="Prüfungsdatum">
                      <template #body="{data}">
                        {{formatDate(data.DATUM)}}
                      </template>
                    </Column>
                    <Column field="INTERVALL" header="Intervall" ></Column>
                    <Column header="Nächste Prüfung">
                      <template #body="{data}">
                        {{formatDate(data.NAECHSTE_PRUEFUNG)}}
                      </template>
                    </Column>
                    <Column field="GESAMTERGEBNIS" header="Gesamtergebnis">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.GESAMTERGEBNIS=='i.O'" style="background: #C8E6C9; color: #256029;">{{slotProps.data.GESAMTERGEBNIS}}</span>
                            <span v-else-if="slotProps.data.GESAMTERGEBNIS=='n.i.O'" style="background: #FFCDD2; color: #C63737;">{{slotProps.data.GESAMTERGEBNIS}}</span>
                            <span v-else>{{slotProps.data.GESAMTERGEBNIS}}</span>
                        </template>
                    </Column>
                    <Column>
                      <template #body="slotProps">
                        <Button v-if="!hide" icon="pi pi-trash" @click="deleteInspektion(slotProps.data)" disabled />
                        <Button v-if="hide" icon="pi pi-trash" @click="deleteInspektion(slotProps.data)" />
                      </template>
                    </Column>
                </DataTable>
			</div>
		</div>
	</div>
  <Dialog modal=true maximizable=true v-model:visible="inspektionDialog" @hide="onHide">
    <Inspektion @close="closeInspektionDialog"></Inspektion>
  </Dialog>
  <Dialog  maximizable=true v-model:visible="inspektionsFormularDialog">
    <InspektionFormular :id="inspektionId" @close="closeInspektionsFormularDialog"></InspektionFormular>
  </Dialog>
</template>

<script>
  import DataService from "@/service/DataService";
  import moment from "moment";
  import InspektionFormular from "@/pages/InspektionFormular";
  import store from "@/store";
	export default {
    components: {InspektionFormular},
    data() {
			return {
        inspektionen: null,
        inspektionUpdate: null,
        inspektionDialog: false,
        inspektionsFormularDialog: false,
        inspektionId: null,
				statuses: [
					'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
				],
        hide: false
			}
		},
    dataService: null,
		created() {
      this.dataService = new DataService();
		},
		async mounted() {
      this.inspektionen = await this.dataService.getInspektionByProduktId(this.$route.params.id);
      this.hide = store.getters.getHide;
		},
    methods: {
      formatDate(dateString){
        return moment(dateString).format('DD.MM.YYYY')
      },
      onRowSelect(event){
        this.inspektionId = event.data.ID;
        this.inspektionsFormularDialog = true;
      },
      onNew(){
        this.inspektionDialog=true;
      },
      async onHide(){
        this.inspektionen = await this.dataService.getInspektionByProduktId(this.$route.params.id);
      },
     async closeInspektionsFormularDialog(data){
        this.inspektionsFormularDialog = data;
        setTimeout(async()=>{this.inspektionen = await this.dataService.getInspektionByProduktId(this.$route.params.id);}, 100)
      },

      async closeInspektionDialog(data){
        this.inspektionDialog = data;
        setTimeout(async()=>{this.inspektionen = await this.dataService.getInspektionByProduktId(this.$route.params.id);}, 100)
      },
      async deleteInspektion(event){
        this.dataService.deleteInspektion(event.ID);
        setTimeout(async()=>{this.inspektionen = await this.dataService.getInspektionByProduktId(this.$route.params.id);}, 100)
      }
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/acticom/styles/badges.scss';


::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
</style>
