import { io } from "socket.io-client";
import store from '../store/index';
import { environment } from "../../environment/environment";


export default class DataService {

    socket = io(environment.backend, {
        query: {
            token: store.state.token
        },
        allowEIO3: true
        }
    );

    constructor() {
    }

//******************************************************************** Unternehmen**********************************************************
    async getUnternehmen(){
        this.socket.on('disconnect', (reason) => {
            console.log(reason)
        })
        await this.socket.emit('queryUnternehmen', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryUnternehmen', (data) => {
                resolve(data);
            });
        });
    }

    async updateUnternehmen (data){
        await this.socket.emit('updateUnternehmen', data)
    }

    async saveUnternehmen (data){
        await this.socket.emit('saveUnternehmen', data)
    }

    async deleteUnternehmen (data){
        await this.socket.emit('deleteUnternehmen', data)
    }

//******************************************************************** Mitarbeiter**********************************************************
    async getStandorteAll(){
        await this.socket.emit('queryStandorteAll', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryStandorteAll', (data) => {
                resolve(data);
            });
        });
    }

    async getMitarbeiterAuftraggeberId(id){
        await this.socket.emit('queryMitarbeiterAuftraggeberId', id);

        return new Promise((resolve) => {
            this.socket.on('queryMitarbeiterAuftraggeberId', (data) => {
                resolve(data);
            });
        });
    }

    async getStandorte(){
        await this.socket.emit('queryStandorte', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryStandorte', (data) => {
                resolve(data);
            });
        });
    }

    async getStandortById(id){
        await this.socket.emit('queryStandortById', id);

        return new Promise((resolve) => {
            this.socket.on('queryStandortById', (data) => {
                resolve(data);
            });
        });
    }

    async updateStandort (data){
        let finalData = data;
        let string = finalData.FK_ID_UNTERNEHMEN.split(" ")
        finalData.FK_ID_UNTERNEHMEN = Number(string[0]);
        await this.socket.emit('updateStandort', finalData)
    }

    async saveStandort (data){
        let finalData = data;
        let string = finalData.FK_ID_UNTERNEHMEN.split(" ")
        finalData.FK_ID_UNTERNEHMEN = Number(string[0]);
        await this.socket.emit('saveStandort', finalData)
    }

    async deleteStandort (data){
        await this.socket.emit('deleteStandort', data)
    }







//******************************************************************** Mitarbeiter**********************************************************
    async getMitarbeiter(){
        await this.socket.emit('queryMitarbeiter', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryMitarbeiter', (data) => {
                resolve(data);
            });
        });
    }

    async updateMitarbeiter (data){
        // let finalData = data;
        // let string = finalData.FK_ID_UNTERNEHMEN.split(" ")
        // finalData.FK_ID_UNTERNEHMEN = Number(string[0]);
        // console.log(finalData)
        await this.socket.emit('updateMitarbeiter', data)
    }

   async saveMitarbeiter (data){
        let finalData = data;
        let string = finalData.FK_ID_UNTERNEHMEN.split(" ")
        finalData.FK_ID_UNTERNEHMEN = Number(string[0]);
        console.log(finalData)
        await this.socket.emit('saveMitarbeiter', finalData)
    }

    async deleteMitarbeiter (data){
        await this.socket.emit('deleteMitarbeiter', data)
    }

//******************************************************************** Aktion**********************************************************
    async getAktionen(){
        await this.socket.emit('queryAktionen', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryAktionen', (data) => {
                resolve(data);
            });
        });
    }

    async getAktionGruppen(){
        await this.socket.emit('queryAktionGruppen', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryAktionGruppen', (data) => {
                resolve(data);
            });
        });
    }

    async getAktionAktionen(){
        await this.socket.emit('queryAktionAktionen', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryAktionAktionen', (data) => {
                resolve(data);
            });
        });
    }

    async updateAktionen (data){
        await this.socket.emit('updateAktionen', data)
    }

    async saveAktionen (data){
        await this.socket.emit('saveAktionen', data)
    }

    async deleteAktionen (data){
        await this.socket.emit('deleteAktionen', data)
    }



//******************************************************************** Aktion**********************************************************

    async getProdukte(gruppe, archiviert){
        await this.socket.emit('queryProdukte', gruppe, archiviert);

        return new Promise((resolve) => {
            this.socket.on('queryProdukte', (data) => {
                resolve(data);
            });
        });
    }


    async getProdukteAll(archiviert){
        await this.socket.emit('queryProdukteAll', {data: 1}, archiviert);

        return new Promise((resolve) => {
            this.socket.on('queryProdukteAll', (data) => {
                resolve(data);
            });
        });
    }

    async getProdukteById(id){
        await this.socket.emit('queryProdukteById', id);

        return new Promise((resolve) => {
            this.socket.on('queryProdukteById', (data) => {
                resolve(data);
            });
        });
    }

    async getProduktHistoryById(id){
        await this.socket.emit('queryProduktHistoryById', id);

        return new Promise((resolve) => {
            this.socket.on('queryProduktHistoryById', (data) => {
                resolve(data);
            });
        });
    }



    async updateProdukte (data){
        let finalData = data;
        if(typeof finalData.FK_ID_AUFTRAGGEBER === "string"){
            let string = finalData.FK_ID_AUFTRAGGEBER.split(" ")
            finalData.FK_ID_AUFTRAGGEBER = Number(string[0]);
        }
        await this.socket.emit('updateProdukte', finalData)
    }

    async saveProdukte (data){
        let finalData = data;

        if(typeof finalData.FK_ID_AUFTRAGGEBER === "string"){
            let string = finalData.FK_ID_AUFTRAGGEBER.split(" ")
            finalData.FK_ID_AUFTRAGGEBER = Number(string[0]);
        }

        await this.socket.emit('saveProdukte', finalData);
    }

    async deleteProdukte (data){
        await this.socket.emit('deleteProdukte', data)
    }

    async archiveProdukte (data){
        await this.socket.emit('archiveProdukte', data)
    }

    async reArchiveProdukte (data){
        await this.socket.emit('reArchiveProdukte', data)
    }

    async getProduktGruppen(){
        await this.socket.emit('queryProduktGruppen', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryProduktGruppen', (data) => {
                resolve(data);
            });
        });
    }

    async saveProduktGruppen (data){
        await this.socket.emit('saveProduktGruppen', data);
    }

    async updateProduktGruppen (data){
        await this.socket.emit('updateProduktGruppen', data)
    }

    async deleteProduktGruppen (data){
        await this.socket.emit('deleteProduktGruppen', data)
    }

//******************************************************************** Aktion**********************************************************
    async getAuftragnehmerMitarbeiter(){
        await this.socket.emit('queryAuftragnehmerMitarbeiter', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryAuftragnehmerMitarbeiter', (data) => {
                resolve(data);
            });
        });
    }

    async getAuftragnehmerMitarbeiterById(ID){
        await this.socket.emit('queryAuftragnehmerMitarbeiterById', ID);

        return new Promise((resolve) => {
            this.socket.on('queryAuftragnehmerMitarbeiterById', (data) => {
                resolve(data);
            });
        });
    }

    async getAuftraggeber(id){
        await this.socket.emit('queryAuftraggeber', id);

        return new Promise((resolve) => {
            this.socket.on('queryAuftraggeber', (data) => {
                resolve(data);
            });
        });
    }

    async getAuftraggeberAll(){
        await this.socket.emit('queryAuftraggeberAll', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryAuftraggeberAll', (data) => {
                resolve(data);
            });
        });
    }

    async getAuftragnehmer(){
        await this.socket.emit('queryAuftragnehmer', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryAuftragnehmer', (data) => {
                resolve(data);
            });
        });
    }

    async getInspektion(id){
        await this.socket.emit('queryInspektion', id);

        return new Promise((resolve) => {
            this.socket.on('queryInspektion', (data) => {
                resolve(data);
            });
        });
    }

    async getInspektionByProduktId(id){
        await this.socket.emit('queryInspektionByProduktId', id);

        return new Promise((resolve) => {
            this.socket.on('queryInspektionByProduktId', (data) => {
                resolve(data);
            });
        });
    }

    async getAktionenByInspektion(id){
        await this.socket.emit('queryAktionenByInspektion', id);

        return new Promise((resolve) => {
            this.socket.on('queryAktionenByInspektion', (data) => {
                resolve(data);
            });
        });
    }

    async saveInspektion (data){
        let finalData = data;
        console.log(finalData.FK_ID_MITARBEITER)
        let string = finalData.FK_ID_MITARBEITER.split(" ")
        finalData.FK_ID_MITARBEITER = Number(string[0]);

        await this.socket.emit('saveInspektion', data);

        return new Promise((resolve) => {
            this.socket.on('queryInspektionId', (data) => {
                resolve(data);
            });
        });
    }

    async saveInspektionAktionen (data){
        await this.socket.emit('saveInspektionAktionen', data)
    }

    async updateInspektion (data){
        await this.socket.emit('updateInspektion', data)
    }

    async updateInspektionAktionen (data){
        await this.socket.emit('updateInspektionAktionen', data)
    }

    async deleteInspektion (ID){
        await this.socket.emit('deleteInspektion', ID);
    }

    async getTemplates(){
        await this.socket.emit('queryTemplates', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryTemplates', (data) => {
                resolve(data);
            });
        });
    }

    async getAktionTemplates(id){
        await this.socket.emit('queryAktionTemplates', id);

        return new Promise((resolve) => {
            this.socket.on('queryAktionTemplates', (data) => {
                resolve(data);
            });
        });
    }

    async saveTemplate (name){
        await this.socket.emit('saveTemplate', name);

        return new Promise((resolve) => {
            this.socket.on('queryTemplateId', (data) => {
                resolve(data);
            });
        });
    }

    async saveAktionTemplates (data){
        await this.socket.emit('saveAktionTemplates', data)
    }

    async updateTemplate (data){
        await this.socket.emit('updateTemplate', data)
    }

    async updateAktionTemplates (data){
        await this.socket.emit('updateAktionTemplates', data)
    }

    async deleteAktionTemplates (ID){
        await this.socket.emit('deleteAktionTemplates', ID);
    }

    async deleteTemplates (data){
        await this.socket.emit('deleteTemplates', data)
    }

//******************************************************************** Servicelevel**********************************************************
    async saveServiceLevel (data){
        await this.socket.emit('saveServiceLevel', data)
    }

    async getServiceLevel(){
        await this.socket.emit('queryServiceLevel', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryServiceLevel', (data) => {
                resolve(data);
            });
        });
    }

    async updateServiceLevel (data){
        await this.socket.emit('updateServiceLevel', data)
    }

    async deleteServiceLevel (data){
        await this.socket.emit('deleteServiceLevel', data)
    }

//******************************************************************** Reperatur**********************************************************
    async getReperaturByProuktId(id){
        await this.socket.emit('queryReperaturByProduktId', id);

        return new Promise((resolve) => {
            this.socket.on('queryReperaturByProduktId', (data) => {
                resolve(data);
            });
        });
    }

    async getReperaturByProuktIdLatest(id){
        await this.socket.emit('queryReperaturByProduktIdLatest', id);

        return new Promise((resolve) => {
            this.socket.on('queryReperaturByProduktIdLatest', (data) => {
                resolve(data);
            });
        });
    }

    async getTechnikerDetails(id){
        await this.socket.emit('queryTechnikerDetails', id);

        return new Promise((resolve) => {
            this.socket.on('queryTechnikerDetails', (data) => {
                resolve(data);
            });
        });
    }

    async saveReperatur (data){
        await this.socket.emit('saveReperatur', data)
    }

    async updateMaengel (data){
        await this.socket.emit('updateMaengel', data)
    }

    async deleteReperatur (ID){
        await this.socket.emit('deleteReperatur', ID);
    }

    //**********************************************************Ereignisse********************************************************

    async getEreignisse(){
        await this.socket.emit('queryEreignisse', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryEreignisse', (data) => {
                resolve(data);
            });
        });
    }


    //**********************************************************Mietvertrag********************************************************

    async getMietvertrag(){
        await this.socket.emit('queryMietvertrag', {data: 1});

        return new Promise((resolve) => {
            this.socket.on('queryMietvertrag', (data) => {
                resolve(data);
            });
        });
    }

    async getMietvertragByProduktId(id){
        await this.socket.emit('queryMietvertragByProduktId', id);

        return new Promise((resolve) => {
            this.socket.on('queryMietvertragByProduktId', (data) => {
                resolve(data);
            });
        });
    }

    async updateMietvertrag (data){
        await this.socket.emit('updateMietvertrag', data)
    }

    async deleteMietvertrag (data){
        await this.socket.emit('deleteMietvertrag', data)
    }


}
