<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
<!--							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />-->
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedMietvertrag || !selectedMietvertrag.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="mietvetrag" v-model:selection="selectedMietvertrag" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Mietveträge" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Mietverträge</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="FK_ID_PRODUKT" header="ID Produkt" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID Produkt</span>
							{{slotProps.data.FK_ID_PRODUKT}}
						</template>
					</Column>
          <Column field="MIETER" header="Mieter" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Mieter</span>
              {{slotProps.data.MIETER}}
            </template>
          </Column>
          <Column field="MIETBEGINN" header="Mietbeginn" :sortable="true">
            <template #body="{data}">
              {{formatDate(data.MIETBEGINN)}}
            </template>
          </Column>
          <Column field="MIETENDE" header="Mietende" :sortable="true">
            <template #body="{data}">
              {{formatDate(data.MIETENDE)}}
            </template>
          </Column>
          <Column field="MIETDAUER" header="Mietdauer" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Mietdauer</span>
              {{slotProps.data.MIETDAUER}}
            </template>
          </Column>
          <Column field="TAGESSATZ" header="Tagessatz in CHF" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Tagessatz</span>
              {{slotProps.data.TAGESSATZ}}
            </template>
          </Column>
          <Column field="KOSTEN" header="Kosten" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Kosten</span>
              {{slotProps.data.KOSTEN}}
            </template>
          </Column>
          <Column field="DASHBOARD" header="Dashboard">
            <template #body="slotProps">
              <span class="p-column-title">Dashboard</span>
              <Button icon="pi pi-link"  @click="onRowSelect(slotProps.data)" />
            </template>
          </Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editMietvetrag(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteMietvetrag(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="mietvetragDialog" class:style="{width: '450px'}" header="Mietvertrag anpassen" :modal="true" class="p-fluid">
          <div class="field">
            <label for="TAGESSATZ">Tagessatz</label>
            <InputText id="name" v-model.trim="mietvetragObj.TAGESSATZ" required="true" autofocus :class="{'p-invalid': submitted && !mietvetragObj.TAGESSATZ}" />
          </div>
					<div class="field">
						<label for="MIETBEGINN">Mietbeginn</label>
            <Calendar inputId="mietbeginn" v-model="mietvetragObj.MIETBEGINN"  show-time="false"></Calendar>
					</div>
          <div class="field">
            <label for="MIETENDE">Mietende</label>
            <Calendar inputId="mietende" v-model="mietvetragObj.MIETENDE" show-time="false"></Calendar>
          </div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveMietvetrag" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMietvetragDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="mietvetragObj"><b>Mietvertrag </b><b>{{mietvetragObj.ID}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteMietvetragDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteMietvetrag" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMietvetragsDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="mietvetragObj">Ausgewählte mietvetrag wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteMietvetragsDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteselectedMietvertrag" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";
import moment from "moment/moment";
import Calendar from "primevue/calendar";

export default {
  components: {Calendar},
	data() {
		return {
      mietvetrag: null,
      mietvetragDialog: false,
			deleteMietvetragDialog: false,
			deleteMietvetragsDialog: false,
      neu: false,
      mietvetragObj: {},
			selectedMietvertrag: null,
			filters: {},
			submitted: false
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.mietvetrag = await this.dataService.getMietvertrag();
  },
	methods: {
		openNew() {
			this.mietvetragObj = {};
			this.submitted = false;
			this.mietvetragDialog = true;
      this.neu = true;
		},
    formatDate(dateString) {
      if (!dateString) {
        return ''; // Gebe einen leeren String zurück, wenn der Wert null oder leer ist
      }

      return moment(dateString).isValid() ? moment(dateString).format('DD.MM.YYYY') : '';
    },

    onRowSelect(event){
      // console.log(event.ID)
      this.$router.push(`/produktdashboard/${event.FK_ID_PRODUKT}`);
    },
		hideDialog() {
			this.mietvetragDialog = false;
			this.submitted = false;
		},
    async saveMietvetrag(){
      this.submitted = true;
      if(this.neu){
          await this.dataService.saveMietvetrag(this.mietvetragObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag erstellt', life: 3000});
          setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);
          this.mietvetragDialog = false;
          this.mietvetragObj = {};

      } else {
          await this.dataService.updateMietvertrag(this.mietvetragObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag aktualisiert', life: 3000});
          setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);
          this.mietvetragDialog = false;
          this.mietvetragObj = {};

      }

    },
    editMietvetrag(mietvetrag) {
      this.mietvetragObj = {...mietvetrag};
      this.mietvetragDialog = true;
      this.neu = false;
    },
    confirmDeleteMietvetrag(mietvetrag) {
      this.mietvetragObj = mietvetrag;
      this.deleteMietvetragDialog = true;
    },
    async deleteMietvetrag() {
      let data = [];
      data.push(this.mietvetragObj);
      await this.dataService.deleteMietvertrag(data);

      this.deleteMietvetragDialog = false;
      this.mietvetragObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag gelöscht', life: 3000});
      setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteMietvetragsDialog = true;
		},
    async deleteselectedMietvertrag() {
      this.deleteMietvetragsDialog = false;
      await this.dataService.deleteMietvertrag(this.selectedMietvertrag);
      this.selectedMietvertrag = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'mietvetrag gelöscht', life: 3000});
      setTimeout(async()=> {this.mietvetrag = await this.dataService.getMietvertrag()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
