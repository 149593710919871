// import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router';
import {createRouter,  createWebHistory} from 'vue-router';

const routes = [

    {
        path: '/',
        name: 'home',
        // exact: true,
        component: () => import('./pages/Home'),
        meta: {
            breadcrumb: [{ label: 'Dashboard' }],
        },
    },
    {
        path: '/unternehmen',
        name: 'unternehmen',
        component: () => import('./pages/UnternehmenCrud.vue'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Unternehmen' }],
        },
    },
    {
        path: '/mitarbeiter',
        name: 'mitarbeiter',
        component: () => import('./pages/MitarbeiterCrud.vue'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Mitarbeiter' }],
        },
    },
    {
        path: '/standorte',
        name: 'standorte',
        component: () => import('./pages/StandortCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Standorte' }],
        },
    },
    {
        path: '/aktionen',
        name: 'aktionen',
        component: () => import('./pages/AktionenCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Aktionen' }],
        },
    },
    {
        path: '/templates',
        name: 'templates',
        component: () => import('./pages/TemplateCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Templates' }],
        },
    },
    {
        path: '/gruppen',
        name: 'gruppen',
        component: () => import('./pages/GruppenCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Produktgruppen' }],
        },
    },
    {
        path: '/serviceLevel',
        name: 'serviceLevel',
        component: () => import('./pages/ServiceLevelCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'ServiceLevel' }],
        },
    },
    {
        path: '/produkte',
        name: 'produkte',
        component: () => import('./pages/ProduktenCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Produkte' }],
        },
    },
    {
        path: '/mietvertraege',
        name: 'mietvertraege',
        component: () => import('./pages/MietvertragCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Mietverträge' }],
        },
    },
    {
        path: '/pflegebetten',
        name: 'pflegebetten',
        component: () => import('./pages/PflegebettenCrud'),
        meta: {
            breadcrumb: [{ parent: 'Produkte', label: 'Pflegebetten' }],
        },
    },
    {
        path: '/sauerstoffgeraete',
        name: 'sauerstoffgeraete',
        component: () => import('./pages/SauerstoffGeraetCrud'),
        meta: {
            breadcrumb: [{ parent: 'Produkte', label: 'Sauerstoffgeräte' }],
        },
    },
    {
        path: '/rollstuehle',
        name: 'rollstuehle',
        component: () => import('./pages/RollstuhlCrud'),
        meta: {
            breadcrumb: [{ parent: 'Produkte', label: 'Rollstühle' }],
        },
    },
    {
        path: '/alleProdukte',
        name: 'alleProdukte',
        component: () => import('./pages/AlleProdukteCrud'),
        meta: {
            breadcrumb: [{ parent: 'Produkte', label: 'Alle' }],
        },
    },
    {
        path: '/archivierteProdukte',
        name: 'archivierteProdukte',
        component: () => import('./pages/ArchivierteProdukteCrud'),
        meta: {
            breadcrumb: [{ parent: 'Stammdaten', label: 'Archivierte Produkte' }],
        },
    },
    {
        path: '/Inspektion',
        name: 'Inspektion',
        component: () => import('./components/InspektionFormular'),
        // meta: {
        //     breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        // },
    },
    {
        path: '/Inspektionformular',
        name: 'Inspektionformular',
        component: () => import('./pages/InspektionFormular'),
        // meta: {
        //     breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        // },
    },
    {
        path: '/InspektionenTable',
        name: 'InspektionenTable',
        component: () => import('./components/InspektionenTable'),
        // meta: {
        //     breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        // },
    },
    {
        path: '/produktdashboard/:id?',
        name: 'produktdashboard',
        component: () => import('./components/ProduktDashboard'),
        // meta: {
        //     breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        // },
    },
    {
        path: '/Inspektionformular/:id',
        name: 'Inspektionformular',
        component: () => import('./pages/InspektionFormular'),
        // meta: {
        //     breadcrumb: [{ parent: 'UI Kit', label: 'Float Label' }],
        // },
    },
    {
        path: "/logout",
        component: () => import('./pages/Logout')
    },
    {
        path: "/einstellungen",
        component: () => import('./pages/Einstellungen')
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue'),
        meta: {
            breadcrumb: [{ parent: 'UI Kit', label: 'File' }],
        },
    }
];

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

export default router;
