<template>
	<div class="layout-invoice-page">
    <Button type="button" label="Drucken" icon="pi pi-print" @click="printClick" class="mb-3"></button>
<!--    <Button type="button" label="Export" class="mb-3" icon="pi pi-file-pdf" @click="exportToPDF"></Button>-->

    <div class="grid layout-invoice-content" >
        <div class="col-12">
            <div class="card" id="element-to-convert">
                <div>
                    <h5>Wartungs-Protokoll</h5>
                    <div class="flex flex-column align-items-start">
                        <img src="layout/images/acticom-logo-bottom.png" alt="invoice-logo"/>
                        <p class="mt-0">{{inspektionName}}</p>
                    </div>
                </div>

                <div class="p-3">
                  <div class="grid">
                  <div class="col-12 md:col-4">
                    <Panel header="Auftraggeber">
                      <div class="grid text-left">
                        <div class="col-12">
                          {{auftraggeberObj.NAME}}
                        </div>
                        <div class="col-12 pt-0">
                          <span>{{auftraggeberObj.STRASSE}} {{auftraggeberObj.NR}} </span>
                        </div>
                        <div class="col-12 pt-0">
                          <span>{{auftraggeberObj.PLZ}} {{auftraggeberObj.ORT}}</span>
                        </div>
                      </div>
                    </Panel>
                  </div>
                  <div class="col-12 md:col-4">
                    <Panel header="Auftragnehmer">
                      <div class="grid text-left">
                        <div class="col-12">
                          {{auftragnehmerObj.NAME}}
                        </div>
                        <div class="col-12 pt-0">
                          <span>{{auftragnehmerObj.STRASSE}} {{auftragnehmerObj.NR}} </span>
                        </div>
                        <div class="col-12 pt-0">
                          <span>{{auftragnehmerObj.PLZ}} {{auftragnehmerObj.ORT}}</span>
                        </div>
                      </div>
                    </Panel>
                  </div>
                    <div class="col-12 md:col-4">
                      <Panel header="Prüfung">
                        <div class="grid text-left">
                          <div class="col-3 font-medium">
                            Prüfdatum
                          </div>
                          <div class="col-9">
                            {{formatDate(inspektionObj.DATUM)}}
                          </div>
                          <div class="col-3 pt-0 font-medium">
                            Intervall
                          </div>
                          <div class="col-9 pt-0">
                             {{inspektionObj.INTERVALL}}
                          </div>
                          <div class="col-3 pt-0 font-medium">
                            Nächste Prüfung
                          </div>
                          <div class="col-9 pt-0">
                            {{formatDate(inspektionObj.NAECHSTE_PRUEFUNG)}}
                          </div>
                          <div class="col-3 pt-0 font-medium">
                            Prüfer
                          </div>
                          <div class="col-9 pt-0">
                            <span>{{auftragnehmerMitarbeiterObj.VORNAME}} {{auftragnehmerMitarbeiterObj.NACHNAME}}</span>
                          </div>
                        </div>
                      </Panel>
                    </div>

                  </div>
                  <h6>{{produktObj.GRUPPE}}</h6>

                  <DataTable :value="produkt" class="p-invoice-datatable-responsive pb-3">
                    <Column field="TYP/MODEL" header="Typ/Model"></Column>
                    <Column field="HERSTELLER" header="Hersteller"></Column>
                    <Column field="SERIENNUMMER" header="Seriennummer"></Column>
                    <Column field="INVENTARNUMMER" header="Inventarnummer"></Column>
                    <Column field="SCHUTZKLASSE" header="Schutzklasse"></Column>
                    <Column field="BAUJAHR" header="Baujahr"></Column>
                    <Column field="KENNZEICHNUNG" header="Kennzeichnung"></Column>
                    <Column field="ETAGE" header="Etage"></Column>
                    <Column field="ZIMMER_NR" header="Zimmer Nr"></Column>
					</DataTable>

					<DataTable :value="aktionen" v-model:selection="selectedAktion" data-key="ID" rowGroupMode="subheader" groupRowsBy="aktionen.GRUPPE" sort-field="AKTION" :sort-order="1" class="p-invoice-datatable-responsive p-datatable-sm py-6" >
            <Column field="AKTION" header="Aktion"></Column>
              <Column field="BAUTEIL" header="Zu prüfendes Bauteil"></Column>
            <Column header="i.O">
              <template #body="slotProps">
                <Checkbox v-if="hide" v-model="slotProps.data.IN_ORDNUNG" :binary="true" />
                <Checkbox v-else v-model="slotProps.data.IN_ORDNUNG" disabled="true" :binary="true" />
              </template>
            </Column>
            <Column header="n.i.O">
              <template #body="slotProps">
                <Checkbox v-if="hide" v-model="slotProps.data.NICHT_IN_ORDNUNG" :binary="true" />
                <Checkbox v-else v-model="slotProps.data.NICHT_IN_ORDNUNG" disabled="true" :binary="true" />
              </template>
            </Column>
					</DataTable>
                      <div class="p-fluid formgrid grid">
                      <div class="col-12 md:col-6">
                            <Panel header="Mängel">
                              <div class="field col-12">
                                <Textarea v-if="hide" id="bauteil" v-model="inspektionObj.MAENGEL"  />
                                <Textarea v-else id="bauteil" v-model="inspektionObj.MAENGEL" disabled  />
                              </div>
                            </Panel>
                        </div>
                        <div class="col-12 md:col-6">
                            <Panel header="Gesamtergebnis STK inkl.BGVA 3">
                                <div class="col-12 md:col-6">
                                  <div class="field-radiobutton col-6">
                                    <RadioButton v-if="hide" id="inordnung" name="inordnung" value="i.O" v-model="inspektionObj.GESAMTERGEBNIS"/>
                                    <RadioButton v-else id="inordnung"  name="inordnung" value="i.O" v-model="inspektionObj.GESAMTERGEBNIS" disabled="true"/>
                                    <label for="inordnung">i.O</label>
                                  </div>
                                  <div class="field-radiobutton col-6">
                                    <RadioButton v-if="hide" id="nichtinordnung" name="nichtinordnung" value="n.i.O" v-model="inspektionObj.GESAMTERGEBNIS"  />
                                    <RadioButton v-else id="nichtinordnung" name="nichtinordnung" value="n.i.O" v-model="inspektionObj.GESAMTERGEBNIS" disabled="true" />
                                    <label for="nichtinordnung">n.i.O</label>
                                  </div>
                                </div>
                            </Panel>
                        </div>
                        <div class="field col-12 pt-3 no-print">
                          <Button v-if="show&&hide" label="Speichern" @click="save"></Button>
                          <Button v-else-if="show&& !hide" label="Speichern" @click="save" disabled></Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>

<script>
import DataService from "@/service/DataService";
import html2pdf from "html2pdf.js/src";
import store from "@/store";
const moment = require('moment');
export default {
  emits: ['close'],
  props: {
    id: Number
  },
	data() {
		return {
      auftraggeber: null,
      auftraggeberObj: {},
      auftragnehmer: null,
      auftragnehmerObj: {},
      auftragnehmerMitarbeiter: null,
      auftragnehmerMitarbeiterObj: {},
      inspektion: null,
      inspektionObj: {},
      inspektionName: '',
      produkt: null,
      produktObj: {},
      aktionen: null,
      produktId: null,
      show: true,
      hide: false
		}
	},
  dataService: null,
  created() {
    this.dataService = new DataService();
  },
  async mounted() {
      this.inspektion = await this.dataService.getInspektion(this.id);

    this.produktId = this.$route.params.id;
    this.auftraggeber = await this.dataService.getAuftraggeber(this.produktId);
    this.auftraggeberObj = this.auftraggeber[0];
    this.auftragnehmer = await this.dataService.getAuftragnehmer();
    this.auftragnehmerObj = this.auftragnehmer[0];
    this.auftragnehmerMitarbeiter = await this.dataService.getAuftragnehmerMitarbeiterById(this.inspektion[0].FK_ID_MITARBEITER);

      this.auftragnehmerMitarbeiterObj = this.auftragnehmerMitarbeiter[0];
    this.inspektionObj = this.inspektion[0];
    this.inspektionName = this.inspektion[0].NAME;
    this.produkt = await this.dataService.getProdukteById(this.produktId);
    this.produktObj = this.produkt[0];
    this.aktionen = await this.dataService.getAktionenByInspektion(this.id);
    this.hide = store.getters.getHide;
  },
  methods: {
		printClick() {
      store.commit('storeRowClass', 'no-print');

      setTimeout(() => {
        window.print()
      }, 100);
		},
    save(){
      this.dataService.updateInspektion(this.inspektionObj);
      this.dataService.updateInspektionAktionen(this.aktionen);
      this.$emit('close', false);
    },
    formatDate(dateString){
      return moment(dateString).format('DD.MM.YYYY')
    },
    exportToPDF() {
      this.show = false;
      let body = document.body
      let html = document.documentElement
      let height = Math.max(body.scrollHeight, body.offsetHeight,
          html.clientHeight, html.scrollHeight, html.offsetHeight)
      let heightCM = height / 35.35
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 1,
        filename: 'wartungs-protokoll.pdf',
        image: {type: 'jpeg', quality: 1},
        html2canvas: { dpi: 192, letterRendering: true },
        jsPDF: {
          orientation: 'landscape',
          unit: 'cm',
          format: [heightCM, 60],
          ignore: ['speichern']
        }
      });
    }
	}
}
</script>

<style scoped>

@media print {

  @page {
    size: A3;
    -webkit-print-color-adjust: exact;
  }
  .no-print, .no-print *
  {
    display: none !important;
  }
}









</style>
