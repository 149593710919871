<template>
	<div class="grid">
		<div class="col-12">
			<div class="card">
				<DataTable :value="mietvertrag"
                    sortMode="single" sortField="ID" :sortOrder="1" scrollable scrollHeight="400px"  responsiveLayout="stack" breakpoint="460px">
                    <Column field="ID" header="Id"></Column>
                    <Column header="Mietbeginn">
                      <template #body="{data}">
                        {{formatDate(data.MIETBEGINN)}}
                      </template>
                    </Column>
                    <Column header="Mietende">
                      <template #body="{data}">
                        {{formatDate(data.MIETENDE)}}
                      </template>
                    </Column>
                    <Column field="MIETDAUER" header="Mietdauer" ></Column>
                    <Column field="TAGESSATZ" header="Tagessatz" ></Column>
                    <Column field="KOSTEN" header="Kosten" ></Column>
                </DataTable>
			</div>
		</div>
	</div>

</template>

<script>
  import DataService from "@/service/DataService";
  import moment from "moment";
  import store from "@/store";
	export default {
    data() {
			return {
        mietvertrag: null,
        mietvertragUpdate: null,
        mietvertragDialog: false,
        mietvertragFormularDialog: false,
        mietvertragId: null,
			}
		},
    dataService: null,
		created() {
      this.dataService = new DataService();
		},
		async mounted() {
      this.mietvertrag = await this.dataService.getMietvertragByProduktId(this.$route.params.id);
      this.hide = store.getters.getHide;
		},
    methods: {
      formatDate(dateString) {
        if (!dateString) {
          return ''; // Gebe einen leeren String zurück, wenn der Wert null oder leer ist
        }

        return moment(dateString).isValid() ? moment(dateString).format('DD.MM.YYYY') : '';
      },
      onRowSelect(event){
        this.mietvertragId = event.data.ID;
      },
      onNew(){
        this.mietvertragDialog=true;
      },
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/acticom/styles/badges.scss';


::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
</style>
