<template>
	<div class="grid">
    <div class="col-12">
      <Toolbar class="mb-4">
        <template v-slot:start>
          <div class="my-2">
            <Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="onNew" />
          </div>
        </template>
      </Toolbar>
    </div>
		<div class="col-12">
			<div class="card">
				<DataTable :value="reperaturen"
                    sortMode="single" sortField="ID" :sortOrder="-1" scrollable scrollHeight="400px" selectionMode="single" @rowSelect="onRowSelect($event)" responsive-layout="stack">
                    <Column field="ID" header="Id"></Column>
                    <Column header="Eingangsdatum">
                      <template #body="{data}">
                        {{formatDate(data.TS_EINGANG)}}
                      </template>
                    </Column>
                    <Column field="STATUS" header="Status" ></Column>
                    <Column>
                      <template #body="slotProps">
                        <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editReperatur(slotProps.data)" />
                        <Button v-if="hide" icon="pi pi-trash" @click="deleteReperatur(slotProps.data)" />
                        <Button v-else icon="pi pi-trash" @click="deleteReperatur(slotProps.data)" disabled />
                      </template>
                    </Column>
                </DataTable>
			</div>
		</div>
	</div>

  <Dialog v-if="hide" v-model:visible="reperaturenDialog" :style="{width: '650px'}" header="Mängel Details" :modal=true class="p-fluid" @hide="hideDialog">
    <div class="field">
      <label for="MAENGEL">Beschreibung</label>
      <Textarea id="maengel" v-model="reperaturenObj.MAENGEL" required="true" rows="10" cols="20" />
    </div>
    <div class="field">
      <label for="angenommen">Annahme</label>
      <ToggleButton id="angenommen" v-model="reperaturenObj.ANGENOMMEN" onLabel="übernommen" offLabel="nicht übernommen" onIcon="pi pi-check" offIcon="pi pi-times" />
    </div>
    <div class="field">
      <label for="Termin">Termin</label>
      <Calendar inputId="termin" v-model="reperaturenObj.TERMIN" show-time="true"></Calendar>
    </div>
    <div class="field">
      <label for="abgeschlossen">Abgeschlossen</label>
      <ToggleButton id="abgeschlossen" v-model="reperaturenObj.ABGESCHLOSSEN" onLabel="abgeschlossen" offLabel="nicht abgeschlossen" onIcon="pi pi-check" offIcon="pi pi-times" />
    </div>
    <div class="field">
      <label for="ABSCHLUSS">Abschlussmeldung</label>
      <Textarea id="abschluss" v-model="reperaturenObj.ABSCHLUSS"  rows="10" cols="20" />
    </div>
    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
      <Button label="Speichern" icon="pi pi-check" class="p-button-text" :loading="loading" @click="saveReperatur" />
    </template>
  </Dialog>

  <Dialog v-else v-model:visible="reperaturenDialog" :style="{width: '650px'}" header="Mängel Details" :modal=true class="p-fluid" @hide="hideDialog">
    <div class="field">
      <label for="MAENGEL">Beschreibung</label>
      <Textarea id="maengel" v-model="reperaturenObj.MAENGEL" required="true" rows="10" cols="20" />
    </div>
    <template #footer>
      <Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
      <Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveReperatur" />
    </template>
  </Dialog>



</template>

<script>
  import DataService from "@/service/DataService";
  import moment from "moment";
  import store from "@/store";
  import EmailService from "@/service/EmailService";
	export default {
    emits: ['reperatur'],
    data() {
			return {
        reperaturen: null,
        loading: false,
        reperaturenDialog: false,
        reperaturenObj: {},
        technikerDetails: null,
        mailObj: {},
        emailData: [],
        neu: false,
        inspektionsFormularDialog: false,
        inspektionId: null,
				statuses: [
					'unqualified', 'qualified', 'new', 'negotiation', 'renewal', 'proposal'
				],
        hide: false
			}
		},
    dataService: null,
		created() {
      this.dataService = new DataService();
		},
		async mounted() {

      this.reperaturen = await this.dataService.getReperaturByProuktId(this.$route.params.id);
      this.hide = store.getters.getHide;

      this.technikerDetails = await this.dataService.getTechnikerDetails(this.$route.params.id);
      if(store.state.token_decoded.unternehmen === 'Acticom AG'){
        this.hide = true;
      }
		},
    async updated() {
      this.reperaturen = await this.dataService.getReperaturByProuktId(this.$route.params.id);
    },
    methods: {
      formatDate(dateString){
        return moment(dateString).format('DD.MM.YYYY')
      },
      onRowSelect(event){
        this.$emit('reperatur', event.data);
        // console.log(event.data)
      },
      onNew(){
        this.neu = true;
        this.reperaturenDialog=true;
      },
      async onHide(){
        this.inspektionen = await this.dataService.getInspektionByProduktId(this.$route.params.id);
      },
      async deleteReperatur(event){
        this.dataService.deleteReperatur(event.ID);
        this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Mängel gelöscht', life: 3000});
        // this.reperaturen = await this.dataService.getReperaturByProuktId(this.$route.params.id);
        this.$emit('reperatur', 'delete');
      },
      async editReperatur(reperatur){
        this.neu = false;
        this.reperaturenObj = {...reperatur};
        console.log(this.reperaturenObj);
        this.reperaturenDialog = true;
      },
      async saveReperatur(){
        this.loading=true;
        if (this.neu){
          this.reperaturenObj.FK_ID_PRODUKT = this.$route.params.id;
          this.reperaturenObj.EINGANGSMELDUNG =`${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name} von ${store.state.token_decoded.unternehmen} hat einen Mängel erfasst`.replace(/\n/gm,"");
          this.reperaturenObj.ERFASSER_EMAIL= store.state.token_decoded.email;
          this.reperaturenObj.ERFASSER_VORNAME= store.state.token_decoded.given_name;
          this.reperaturenObj.ERFASSER_NACHNAME= store.state.token_decoded.family_name;
          this.dataService.saveReperatur(this.reperaturenObj);
          //this.reperaturen = await this.dataService.getReperaturByProuktId(this.$route.params.id);
          this.reperaturenDialog = false;
          this.$emit('reperatur', this.reperaturenObj);
          this.reperaturenObj = {};

          console.log(this.technikerDetails);

          this.technikerDetails.forEach((el) => {
              let emailItem = {
                  to: el.email,
                  name: `${el.VORNAME} ${el.NACHNAME}`,
                  message: `${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name} von ${store.state.token_decoded.unternehmen} hat einen Mängel zum Produkt mit ID ${this.$route.params.id} erfasst`,
                  link: this.$route.fullPath
              };
              this.emailData.push(emailItem);
          });

          let erfasserMailItem1 = {
              to: store.state.token_decoded.email,
              name: `${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name}`,
              message: `Die von Ihnen erfasste Mängel zu Produkt mit ID ${this.$route.params.id} wurde erfasst und an den verantwortlichen Personen weitergeleitet`,
              link: this.$route.fullPath
          };
          this.emailData.push(erfasserMailItem1);
          let response = await EmailService.sendEmail(this.emailData);
          console.log('E-Mail gesendet: ', response.data);
          this.emailData = [];
          this.loading=false;

        } else {

          if(this.reperaturenObj.ANGENOMMEN && !this.reperaturenObj.ABGESCHLOSSEN){
            this.reperaturenObj.ANNAHMEMELDUNG = `${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name} von ${store.state.token_decoded.unternehmen} hat den Mängel zur Bearbeitung angenommen`;
            console.log(this.reperaturenObj.ERFASSER_EMAIL)
              let erfasserMailItem2 = {
                  to: this.reperaturenObj.ERFASSER_EMAIL,
                  name: `${this.reperaturenObj.ERFASSER_VORNAME} ${this.reperaturenObj.ERFASSER_NACHNAME}`,
                  message: `${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name} von ${store.state.token_decoded.unternehmen} hat den Mängel zum Produkt mit ID ${this.$route.params.id} zur Bearbeitung angenommen`,
                  link: this.$route.fullPath
              };
            if(this.reperaturenObj.ERFASSER_EMAIL !== null){
                this.emailData.push(erfasserMailItem2);
            }

              let response = await EmailService.sendEmail(this.emailData);
              this.loading=false;
              this.emailData = [];
              console.log('E-Mail gesendet: ', response.data);
          }
          if(this.reperaturenObj.ABGESCHLOSSEN && this.reperaturenObj.ANGENOMMEN){
            this.reperaturenObj.ABSCHLUSSMELDUNG = `${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name} von ${store.state.token_decoded.unternehmen} hat den Mängel behoben`

              let erfasserMailItem3 = {
                  to: this.reperaturenObj.ERFASSER_EMAIL,
                  name: `${this.reperaturenObj.ERFASSER_VORNAME} ${this.reperaturenObj.ERFASSER_NACHNAME}`,
                  message: `${store.state.token_decoded.given_name} ${store.state.token_decoded.family_name} von ${store.state.token_decoded.unternehmen} hat den Mängel behoben`,
                  link: this.$route.fullPath
              };

              if(this.reperaturenObj.ERFASSER_EMAIL !== null){
                  this.emailData.push(erfasserMailItem3);
              }
              let response = await EmailService.sendEmail(this.emailData);
              this.loading=false;
              this.emailData = [];
              console.log('E-Mail gesendet: ', response.data);
              console.log(this.reperaturenObj)
          }


          this.dataService.updateMaengel(this.reperaturenObj);

          //this.reperaturen = await this.dataService.getReperaturByProuktId(this.$route.params.id);
          this.reperaturenDialog = false;
          this.$emit('reperatur', this.reperaturenObj);
          this.reperaturenObj = {};
        }


      },
      async hideDialog(){
        this.reperaturenObj = {};
        this.reperaturenDialog = false;
      }
		}
	}
</script>

<style scoped lang="scss">
@import '../assets/acticom/styles/badges.scss';


::v-deep(.p-progressbar) {
	height: .5rem;
	background-color: #D8DADC;

	.p-progressbar-value {
		background-color: #607D8B;
	}
}
</style>
